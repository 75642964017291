import React, { useState, useEffect } from "react";

import styled from "styled-components";
// React Component

import Burger from "@animated-burgers/burger-rotate";
// don't forget the styles
import "@animated-burgers/burger-rotate/dist/styles.css";
import { WebSiteContext } from "../app.v2.js";
const Span = styled.span`
  display: block;
  position: fixed;
  top: 10px;
  transition: 0.4s;
  z-index: 5;
  padding: 10px 0px;
  right: 30px;

  @media only screen and (min-width: 576px) {
    right: 30px;
    display: block;
  }
  @media only screen and (min-width: 768px) {
    right: 40px;
    display: none;
  }
  @media only screen and (min-width: 992px) {
    right: 80px;
    display: none;
  }
  @media only screen and (min-width: 1200px) {
    right: 80px;
    display: none;
  }
`;
export default function MenuButton() {
  const { state, dispatch } = React.useContext(WebSiteContext);
  const { menu_is_open } = state;

  const [scroll, setScroll] = useState(0);
  const [lastScroll, setLastScroll] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.pageYOffset);
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (!menu_is_open) {
        if (scroll > lastScroll && scroll > 50) {
          document.getElementById("button").style.top = "-80px";
        } else {
          document.getElementById("button").style.top = "0";
        }
        setLastScroll(scroll);
      }
    }, 50);
  }, [scroll]);

  const showMenu = () => {
    if (menu_is_open) {
      dispatch({ type: "CLOSE" });
    } else {
      dispatch({ type: "OPEN" });
    }
  };
  return (
    <Span className="menu-button" id="button">
      <Burger isOpen={menu_is_open} onClick={showMenu} />
    </Span>
  );
}
