import React from "react";
import Section from "../section";
import FeaturedProject from "./projects/featuredProject";
import styled from "styled-components";

// import Project from "./projects/project";
// import editorCode from "../../asserts/images/editor-code.png";
// import sovence from "../../asserts/images/sovence.png";

const articles = [
  {
    name: "How to Use Memoization to Reduce Server Requests and Load Times",
    description:
      "Learn how to optimize website performance using Memoization to Enhance web development skills for faster, efficient websites. Step-by-step examples and explanations for implementing Memoization in React applications. Reduce server requests, improve load times, and enhance user experience.",
    tech: ["React", "javascript", "Memzati"],
    external:
      "https://medium.com/@ismail.hocine.dev/how-to-use-memoization-to-reduce-server-requests-and-load-times-3afabe41e8a7",
    img: "https://miro.medium.com/v2/resize:fit:720/format:webp/1*Rr_kvZft2U-XO-lamIiNog.png",
  },
  {
    name: "Boost React Performance with Intersection Observer API for Image Loading",
    description: `Learn how to optimize website performance using Intersection Observer API. Efficiently load images, reduce page size, and create seamless user experiences. Step-by-step examples and explanations for integrating with React. Enhance web development skills for faster, efficient websites.`,
    tech: ["React", "TypeScript", "Intersection Observer API"],
    external: "https://medium.com/p/9eca0283d91b",
    img: `https://miro.medium.com/v2/resize:fit:720/format:webp/1*DQ7raSJPH1LFU0MYgLLq9Q.png`,
  },
];

export default function Articles() {
  return (
    <Section name="Articles" id="articles">
      <ProjectGrid>
        {articles.map((item, index) => (
          <FeaturedProject
            key={"featured-project-" + index}
            name={item.name}
            description={item.description}
            tech={item.tech}
            index={index}
            img={item.img}
            external={item.external}
            github={item.github}
            titleLink={true}
          />
        ))}
      </ProjectGrid>
      {/* <h2 style={{ color: "white", textAlign: "center", margin: "100px" }}>
        Other project
      </h2>
      <RowProjects>
        {projectsList.map((item, index) => (
          <Project
            key={"featured-project-" + index}
            name={item.name}
            description={item.description}
            tech={item.tech}
            index={index}
            external={item.external}
            github={item.github}
          />
        ))}
      </RowProjects> */}
    </Section>
  );
}
const ProjectGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  @media only screen and (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;
