import styled from "styled-components";

export const Container = styled.div`
  max-width: 1100px !important;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
  @media screen and (min-width: 576px) {
    padding-right: 40px;
    padding-left: 40px;
  }
`;

export const BarContainer = styled.div`
  margin-right: 2d0px;
  margin-left: 20px;
  @media screen and (min-width: 576px) {
    margin-right: 30px;
    margin-left: 30px;
  }
  @media screen and (min-width: 768px) {
    margin-right: 40px;
    margin-left: 40px;
  }
  @media screen and (min-width: 992px) {
    margin-right: 80px;
    margin-left: 80px;
  }
  @media screen and (min-width: 1200px) {
    margin-right: 80px;
    margin-left: 80px;
  }
`;

export const HeroStyled = styled.div`
  text-align: left;
  padding: 20vh 0px;
  width: 100%;

  min-height: 30vh;
  display: grid;
  grid-template-columns: 30% 70%;
  direction: rtl;

  //   @media only screen and (max-width: 600px) {
  //     grid-template-columns: 100%;
  //     direction: ltr;
  //   }
  //   @media only screen and (max-width: 576px) {
  //     grid-template-columns: 100%;
  //     direction: ltr;
  //   }
  //   @media only screen and (max-width: 768px) {
  //     grid-template-columns: 100%;

  //     direction: ltr;
  //   }
  @media only screen and (max-width: 992px) {
    grid-template-columns: 100%;
    padding: 12vh 0px 6vh 0px;
    // margin: 6vh 0px 0px 0px;
  }
`;

export const Image = styled.img`
  display: block;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0px
  opacity: 0.9;
  //   @media screen and (min-width: 600px) {
  //     width: 70%;
  //   }
  //   @media screen and (min-width: 576px) {
  //     width: 70%;
  //   }
  //   @media screen and (min-width: 768px) {
  //     width: 70%;
  //   }
  @media only screen and (max-width: 992px) {
    width: 60%;
    max-width: 300px;
    margin-bottom: 80px
  }
`;
