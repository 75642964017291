import React from "react";
import Section from "../section";
import ismail from "../../asserts/images/1658937336548.jpeg";
import { GoCode } from "react-icons/go";
import styled from "styled-components";
const Ul = styled.ul`
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  list-style: none;
  margin: 0;
  padding: 0;
`;

export default function About() {
  return (
    <Section name="About Me" id="about">
      <div className="section-about">
        <div
          style={{ margin: " 0px 20px 20px 0px" }}
          data-aos-duration="1400"
          data-aos="fade-up"
          data-aos-once
        >
          <img src={ismail} alt="an old car" className="img-animation" />
        </div>
        <div data-aos-duration="1400" data-aos="fade-up">
          <p style={{ color: "#B2BEE3", fontSize: "18px" }}>
            Hello! My name is Ismail Hocine and I am passionate about web
            development. My journey began in 2016 when I decided to explore the
            world of creating things on the internet.
          </p>
          <p style={{ color: "#B2BEE3", fontSize: "18px" }}>
            now i'm a full-stack Web developer with <b>+4 years</b> of
            experience in designing, maintaining, testing and building scalable
            web applications. i produce a robust web solutions by adhering to
            current methods and standards.I Worked on more than 17 projects,
            such as a Transfer money platform, an eCommerce Marketplace
            Platform, and a Delivery system. Used several modern JS frameworks
            such as React.Js and Node.js.
          </p>
          <p style={{ color: "#B2BEE3", fontSize: "18px" }}>
            Here are a few technologies I’ve been working with recently:
          </p>
          <Ul style={{ color: "#B2BEE3", fontSize: "18px" }}>
            <li>
              <GoCode color="#62ECD0" style={{ marginRight: "10px" }} />
              JavaScript (ES6+)
            </li>
            <li>
              {" "}
              <GoCode color="#62ECD0" style={{ marginRight: "10px" }} />
              React
            </li>
            <li>
              {" "}
              <GoCode color="#62ECD0" style={{ marginRight: "10px" }} />
              Node.Js
            </li>
            <li>
              {" "}
              <GoCode color="#62ECD0" style={{ marginRight: "10px" }} />
              TypeScript
            </li>
            <li>
              {" "}
              <GoCode color="#62ECD0" style={{ marginRight: "10px" }} />
              React Native
            </li>
            <li>
              {" "}
              <GoCode color="#62ECD0" style={{ marginRight: "10px" }} />
              GraphQl
            </li>
          </Ul>
        </div>
      </div>
    </Section>
  );
}
