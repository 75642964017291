import React from "react";
import { WebSiteContext } from "../app.v2.js";
import styled from "styled-components";
const StyledAside = styled.aside`
  position: fixed;
  width: 300px;
  transition: 0.3s;
  right: ${(props) => (props.open ? 0 : "-300px")};
  top: -80px;
  bottom: 0;
  background-color: #0a293c;
  box-shadow: -10px 0px 10px -5px rgba(0, 0, 0, 0.75);
  z-index: 4;
`;
const List = styled.ul`
  margin-top: 200px;
  padding: 0;
  color: white;
  font-weight: bold;
`;
const Item = styled.li`
  list-style: none;
  margin: 0px;
  padding: 10px 0px 10px 25px;
  cursor: pointer;
  font-size: 16px;
`;
export default function Side() {
  const {
    state: { menu_is_open },
    dispatch,
  } = React.useContext(WebSiteContext);

  const ref = React.useRef(null);

  const handleClickOutside = (event) => {
    if (
      ref.current &&
      !ref.current.contains(event.target) &&
      !event.target.closest(".menu-button")
    ) {
      closeMenu();
    }
  };
  const closeMenu = () => {
    dispatch({ type: "CLOSE" });
  };
  React.useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  });

  const scrollToElement = (id) => {
    dispatch({ type: "CLOSE" });
    setTimeout(() => {
      if (id === "home") {
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        const element = document.getElementById(id);
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 50);
  };

  return (
    <StyledAside ref={ref} open={menu_is_open}>
      <List>
        <Item onClick={() => scrollToElement("home")}>Home</Item>
        <Item onClick={() => scrollToElement("about")}>About</Item>
        <Item onClick={() => scrollToElement("articles")}>Articles</Item>
        <Item onClick={() => scrollToElement("projects")}>Projects</Item>
        <Item onClick={() => scrollToElement("contact")}>Contact</Item>
      </List>
    </StyledAside>
  );
}
