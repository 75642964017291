import React from "react";
import image from "../../asserts/images/undraw_developer_activity_re_39tg.svg";
import { HeroStyled, Image } from "../../styles/styleComponent";
import styled from "styled-components";
const Button = styled.a`
  color: black;
  width: 200px; /* updated the width */
  font-size: clamp(7px, 3vw, 15px);
  font-weight: 600;
  background-color: #62ecd0;
  padding: 10px 30px;
  text-decoration: none;
  border-radius: 5px;
`;

const P = styled.p`
  color: #8892b0;
  width: 90%;
  margin-top: 20px;
  margin-ottom: 20px;
  fontsize: 18px;
  @media only screen and (max-width: 992px) {
    font-size: 16px;
  }
`;
const Content = styled.div`
  direction: ltr;
  margin-top: 30px;
  padding-right: 30px;
  @media only screen and (max-width: 992px) {
    padding-right: 0px;
  }
`;
export default function Hero() {
  return (
    <HeroStyled>
      <div data-aos="zoom-in" data-aos-once data-aos-duration="1600">
        <Image src={image} alt="ismail hocine image" />
      </div>
      <Content>
        <h1
          className="big-heading"
          data-aos="fade-up"
          data-aos-once
          data-aos-duration="900"
          style={{
            marginBottom: "20px",
          }}
        >
          Ismail Hocine
        </h1>
        <h2
          className="big-heading-2"
          data-aos="fade-up"
          data-aos-once
          data-aos-duration="1300"
        >
          I translate ideas into interactive web solutions.
        </h2>
        <P data-aos="fade-up" data-aos-once data-aos-duration="1600">
          I’m a web Developer specializing in building exceptional digital
          experiences. Currently, I’m looking for new opportunity
        </P>
        <div
          data-aos="fade-up"
          data-aos-once
          data-aos-duration="2200"
          style={{ marginTop: "30px" }}
        >
          <Button
            as="a"
            href="https://api.ismailhocine.com/public/uploads/Resume_ismail_HCOINE-1610181729227.pdf"
            aria-label="resume" //www.linkedin.com/in/ismailhocine"
            target="_blank"
            rel="noreferrer"
          >
            Resume
          </Button>
        </div>
      </Content>
    </HeroStyled>
  );
}
