import React from "react";
import Section from "../section";
import FeaturedProject from "./projects/featuredProject";
import styled from "styled-components";
import editorCode from "../../asserts/images/newImg/4.jpg";
import CityExplor from "../../asserts/images/newImg/map 2.jpg";
import Task from "../../asserts/images/newImg/task.jpg";
import Quiz from "../../asserts/images/newImg/quiz.jpg";
import Music from "../../asserts/images/newImg/music.jpg";
import WaslaImg from "../../asserts/images/newImg/wasla.jpg";

const featuredPproject = [
  {
    name: "Sleek city explorer",
    description: `a sleek city explorer! 🏙️ With a minimalist frontend fetching data from a robust backend API, this project showcases cities worldwide. Each city card offers a glimpse into its charm, and with a simple click, a modal unveils detailed information and a dynamic map powered by OpenMapmore. Explore the world, one city at a time, with this elegant and user-friendly application!`,
    tech: ["React", "Typescript", "Express.Js", "Node.Js", "cypress"],
    external: "https://challenge-cities.netlify.app/",
    github:
      "https://github.com/hocineismail/challenge-cities?tab=readme-ov-file",
    img: CityExplor,
  },
  {
    name: "Task Master",
    description: `TaskMaster, the ultimate task management solution. Seamlessly create accounts without passwords and effortlessly access them upon return. Create tasks with customizable colors, delve into nested checklists for structured project management, and employ color-coded to-do items with emojis for clarity and efficiency. TaskMaster streamlines your workflow, making productivity effortless and intuitive.`,
    tech: [
      "Webpack 5",
      "React",
      "Redux",
      "Typescript",
      "Tests: Jest + React-library-testing",
      "PWA",
      "Offline-first strategy",
    ],
    external: "https://deployment--melodic-gumption-1c183e.netlify.app/",
    github:
      "https://github.com/hocineismail/cendas-mini-task/edit/main/README.md",
    img: Task,
  },
  {
    name: "Online editor Code",
    description: `I have built is an online code editor that allows users to write and execute JavaScript 
    and HTML code, and view the results directly 
    in the browser. The platform also allows users to 
    create new files and delete existing ones, 
    providing flexibility in managing 
    their projects. Additionally, 
    the platform can be used as a tool for 
    learning, as it allows users to experiment with 
     different code snippets and see the 
     results in real-time.`,
    tech: ["React", "Javascript"],
    external: "https://hocineismail.github.io/react-editor-js-html",
    github: "https://github.com/hocineismail/react-editor-js-html",
    img: editorCode,
  },
  {
    name: "Recorder and Music Player",
    description: `The Recorder and Music Player project is a dynamic application that combines the functionalities of a voice recorder and a music player. It provides a seamless experience for recording your own audio and enjoying your favorite music tracks. The project utilizes modern technologies, such as React and TypeScript, to deliver a reliable and user-friendly interface.`,
    tech: ["React", "Javascript"],
    external: "https://recorder-music-player.netlify.app/",
    github: "https://github.com/hocineismail/Music-player",
    img: Music,
  },
  {
    name: "Quiz Online",
    description: `The project is an online quiz for JavaScript that allows users to test their knowledge and skills on the language with a set of questions related to JavaScript, each with a designated time limit. After completing the quiz, users can view their results, including their score, and it provides a convenient and 
    interactive way to improve understanding of 
    JavaScript and track progress..`,
    tech: ["React", "Javascript"],
    external: "https://hocineismail.github.io/Javascript-Online-Quiz-/",
    github: "https://github.com/hocineismail/Javascript-Online-Quiz-",
    img: Quiz,
  },
  {
    name: "Wasla",
    description: `  The project is a mobile app that allows users to request delivery to their current location and track it in real-time. It uses GPS to determine location and provides a simple interface for placing requests and tracking deliveries.`,
    tech: [
      "React, React Native",
      "Node.js",
      "Express.js",
      "Javascript",
      "Mongodb",
    ],
    external:
      "https://play.google.com/store/apps/details?id=com.wasladelivery.wasladeliveryApp",
    img: WaslaImg,
  },
];

export default function Projects() {
  return (
    <Section name="Other Projects">
      <ProjectGrid>
        {featuredPproject.map((item, index) => (
          <FeaturedProject
            key={"featured-project-" + index}
            name={item.name}
            description={item.description}
            tech={item.tech}
            index={index}
            img={item.img}
            external={item.external}
            github={item.github}
          />
        ))}
      </ProjectGrid>
      {/* <h2 style={{ color: "white", textAlign: "center", margin: "100px" }}>
        Other project
      </h2>
      <RowProjects>
        {projectsList.map((item, index) => (
          <Project
            key={"featured-project-" + index}
            name={item.name}
            description={item.description}
            tech={item.tech}
            index={index}
            external={item.external}
            github={item.github}
          />
        ))}
      </RowProjects> */}
    </Section>
  );
}
const ProjectGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  @media only screen and (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;
