import React, { useRef, useEffect } from "react";

const MobImg = ({ src, alt }) => {
  const imageRef = useRef(null);
  const [isIntersecting, setIsIntersecting] = React.useState(false);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        console.log(entry);
        if (entry.isIntersecting) {
          setIsIntersecting(entry.isIntersecting);
        }
      },
      { rootMargin: "0px" }
    );

    observer.observe(imageRef.current);
    return () => observer.disconnect();
  }, []);
  useEffect(() => {
    if (isIntersecting) {
      const dataSrc = imageRef.current.getAttribute("data-src");
      if (dataSrc) {
        imageRef.current.src = dataSrc;
      }
    }
  }, [isIntersecting]);

  return (
    <img
      data-test-id="component-image"
      className=" block-position mob-img"
      style={{
        objectFit: "contain",
      }}
      ref={imageRef}
      src={src}
      alt={alt}
      data-src={src}
    />
  );
};

export default MobImg;
