import React from "react";
import Section from "../section";
import FeaturedProject from "./projects/featuredProject";
import styled from "styled-components";
// images for my projects
import Amana from "../../asserts/images/newImg/smartmockups_lier7rop.jpg";
import DarnaDeco from "../../asserts/images/newImg/2.jpg";
import sovence from "../../asserts/images/newImg/3.jpg";
import Education from "../../asserts/images/newImg/smartmockups_lwdgfaa1.jpg";
import LazyLoading from "../../asserts/images/newImg/lazy-loading.jpg";

const featuredPproject = [
  {
    name: "E-Learning Platform",
    description: `This project is  platform is an online learning marketplace tailored to the Algerian community, allowing users to teach and take courses across various subjects. It fosters educational growth by connecting local teachers and learners, offering accessible and high-quality content.`,
    tech: ["Strapi", "SQL", "React", "TypeScript", "S3", "CI/CD Pipelies"],
    external: "https://edu.algerspace.com",
    img: Education,
  },
  {
    name: "Sovece8.com",
    description: `This project is a student-centered platform for knowledge sharing and collaboration, 
    where students can ask and answer questions, and connect 
    with teachers for support. 
    It provides an interactive and dynamic environment for learning.`,
    tech: ["React", "Javascript", "Node.js", "express", "SQL"],
    external: "https://sovece8.com/",
    img: sovence,
  },
  {
    name: "Lazy Images Gallery",
    description: `Lazy-Images-Gallery is a dynamic image gallery that utilizes lazy loading techniques inspired by popular platforms like Unsplash and Pinterest. This project leverages the Intersection Observer API to efficiently load images as they come into the viewport, enhancing performance and user experience.`,
    tech: ["React", "TypeScript"],
    external: "https://lazy-images-gallery.netlify.app",
    github: "https://github.com/hocineismail/Lazy-Images-Gallery",
    img: LazyLoading,
  },
  {
    name: "Darna Deco",
    description: `An e-commerce website that allows users to filter products by price, size, and category. The platform features product slideshows and a user-friendly form for easy order placement. The integration of a REST API and Firebase ensures seamless functionality for a smooth user experience.`,
    tech: [
      "React",
      "Javascript",
      "Styled-components",
      "Recaptcha v3",
      "Firebase",
    ],
    external: "https://darnadeco.netlify.app",
    img: DarnaDeco,
  },
  {
    name: "Amana Transfers",
    description: `This project is a platform that enables users to send money from anywhere in the world to their family in Algeria. It allows users to deposit funds into a virtual wallet and make transfers through various methods such as cash pick-up, bank deposit, and home delivery.`,
    tech: ["React", "Typescript", "styled-components", "firebase"],
    external: "https://amanatransfers.com/",
    img: Amana,
  },
];

export default function Highlighted() {
  return (
    <Section name="Highlighted Projects" id="projects">
      <ProjectGrid>
        {featuredPproject.map((item, index) => (
          <FeaturedProject
            key={"featured-project-" + index}
            name={item.name}
            description={item.description}
            tech={item.tech}
            index={index}
            img={item.img}
            external={item.external}
            github={item.github}
          />
        ))}
      </ProjectGrid>
      {/* <h2 style={{ color: "white", textAlign: "center", margin: "100px" }}>
        Other project
      </h2>
      <RowProjects>
        {projectsList.map((item, index) => (
          <Project
            key={"featured-project-" + index}
            name={item.name}
            description={item.description}
            tech={item.tech}
            index={index}
            external={item.external}
            github={item.github}
          />
        ))}
      </RowProjects> */}
    </Section>
  );
}
const ProjectGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  @media only screen and (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;
