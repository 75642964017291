import React from "react";
import styled from "styled-components";
import email from "../../asserts/images/mail.svg";
import { FiLinkedin, FiTwitter, FiInstagram, FiGithub } from "react-icons/fi";
import Section from "../section";

const Div = styled.div`
  color: white;
  padding: 10px;
  margin: auto;
  margin-bottom: 150px;
  display: grid;
  grid-template-columns: 3fr 2fr;
  @media only screen and (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`;
const ImageHolder = styled.div`
  display: block;
  @media only screen and (max-width: 992px) {
    display: none;
  }
`;
const Heading = styled.h2`
  color: #ccd6f6;
  font-size: clamp(15px, 6vw, 30px);
  font-weight: 600;
`;

const P = styled.p`
  color: #ccd6f6;
  font-size: clamp(16px, 3vw, 18px);
  font-weight: 400;
  margin-bottom: 40px;
  padding-right: 30px;
  @media only screen and (max-width: 992px) {
    padding-right: 0px;
  }
`;

const Button = styled.a`
  color: black;
  width: 200px; /* updated the width */
  font-size: clamp(7px, 3vw, 15px);
  font-weight: 600;
  background-color: #62ecd0;
  padding: 10px 30px;
  text-decoration: none;
  border-radius: 5px;
`;
const List = styled.ul`
  list-style: none;
  text-align: center;
  max-height: 10px !important;
  font-weight: bold;
  padding: 0;
  margin: 0;
  posiyion: absolute;
  z-index: 5 !important;
`;
const Item = styled.li`
  list-style: none;
  display: ${(props) => (props.mobile ? "none" : "inline")};
  margin: 0px;
  text-align: left;
  max-height: 1 0px !important;
  padding: 10px;
  cursor: pointer;
  font-size: 16px;
  z-index: 5 !important;
  @media (max-width: 768px) {
    display: ${(props) => (props.mobile ? "inline" : "none")};
  }
`;
export default function Contact() {
  return (
    <>
      <Section name="Contact">
        <Div
          data-aos="fade-up"
          data-aos-once
          data-aos-duration="1400"
          style={{ margin: "0px 0px" }}
          id="contact"
        >
          <div>
            <P>
              I am open to new opportunities and eager to connect with
              like-minded individuals. Whether you have an idea to share or just
              want to say hello, feel free to reach out to me. I'll make sure to
              respond promptly. Contact Now or <b>ismailhocine93@gmail.com</b>
            </P>
            <Button as="a" href="mailto:ismailhocine93@gmail.com">
              Contact
            </Button>
          </div>
          <ImageHolder>
            <img
              src={email}
              alt="contact"
              style={{
                width: "100%",
                maxWidth: "300px",
                float: "right",
                marginTop: "-10px",
                display: "block",
              }}
            />
          </ImageHolder>
        </Div>
      </Section>
      <List>
        <Item mobile style={{ height: "30px", color: "white" }}>
          <a
            href="https://github.com/hocineismail"
            aria-label="GitHub"
            target="_blank"
            rel="noreferrer"
          >
            <FiGithub size={23} color="white" />
          </a>
        </Item>
        <Item mobile style={{ height: "30px", color: "white" }}>
          <a
            href="https://www.instagram.com/is.mael.dev"
            aria-label="Instagram"
            target="_blank"
            rel="noreferrer"
          >
            <FiInstagram size={23} color="white" />
          </a>
        </Item>
        <Item mobile style={{ height: "30px", color: "white" }}>
          <a
            href="https://twitter.com/ismailhocine2"
            aria-label="Twitter"
            target="_blank"
            rel="noreferrer"
          >
            <FiTwitter size={23} color="white" />
          </a>
        </Item>
        <Item mobile style={{ height: "30px", color: "white" }}>
          <a
            href="https://www.linkedin.com/in/ismailhocine"
            aria-label="Linkedin"
            target="_blank"
            rel="noreferrer"
          >
            <FiLinkedin size={23} color="white" />
          </a>
        </Item>
      </List>
      <footer
        style={{
          textAlign: "center",
          color: "#ccd6f6",
          marginTop: "30px",
          fontSize: "14px",
        }}
      >
        {" "}
        Copyright © 2023 built by ismail hocine
      </footer>
    </>
  );
  // return <Section name="eddd">hhjhj</Section>;
  // return (
  //   <Div id="contact">
  //     <Heading>
  //       {" "}
  //       <span style={{ color: "#62ecd0" }}>{`< `}</span>Contact Me{" "}
  //       <span style={{ color: "#62ecd0" }}>{`/>`}</span>
  //     </Heading>{" "}
  //     <P>
  //       I am open to new opportunities and eager to connect with like-minded
  //       individuals. Whether you have an idea to share or just want to say
  //       hello, feel free to reach out to me. I'll make sure to respond
  //       promptly.Connect Now
  //     </P>
  //     <Button as="a" href="mailto:ismailhocine@gmail.com">
  //       Contact
  //     </Button>
  //   </Div>
  // );
}
